<template>
  <WidgetTemplate :widget-name="this.name" @removeWidget="$emit('removeWidget', name)">
    <template #main>
      <div v-if="tasks.length > 0" class="w-100">
        <div class="text-left flex-row">
          <div class="card-item col-12" v-for="(task, index) in tasks" v-bind:key="index">
            <Card
              :carouselImage="task" :username="task.user.username" :slug="task.slug"
              :user_rating="task.user.review_average" :user_count="task.user.review_count"
              :user_online="task.user.last_active ? isUserOnline(task.user.last_active) : false"
              :imageIcon="task.user.photo_path" :description="task.title" :rating="task.review_average"
              :count="task.review_count"
              :price="task.packages[0].price" :deliveryTime="task.packages[0].delivery" :hash="task.hash"
              :position="index + 1" :sellerInfo="task.user" :select="task.favourite"
              :primaryCategorySlug="task.categories[0].slug" :isHeroTask="task.hero" class="py-3"
            />
          </div>
        </div>
      </div>
      <div v-else-if="tasksLoaded" class="mx-auto d-flex flex-col align-items-center my-5">
        <div class="mt-2">{{ $t('search_no_tasks_message') }}</div>
      </div>
      <div v-else class="mx-auto d-flex flex-col align-items-center my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div class="mt-2">{{ $t('loading_tasks') }}</div>
      </div>
    </template>
  </WidgetTemplate>
</template>

<script>
import apiService from '../../apiService'
import helper from '../../helper'
import Card from '../Task/Card'
import WidgetTemplate from '@/components/Widgets/WidgetTemplate.vue'

export default {
  name: 'RecentWidget',
  widgetType: 'Buyer',
  components: {
    Card,
    WidgetTemplate
  },
  props: {
    limit: {
      type: Number,
      required: false,
      default: 4
    }
  },
  data () {
    return {
      name: this.$options.name,
      tasks: [],
      tasksLoaded: false
    }
  },
  created () {
    this.getLastViewed()
  },
  methods: {
    getLastViewed () {
      apiService.getLastViewed(this.limit).then((resp) => {
        this.tasksLoaded = true
        this.tasks = resp.data.data.map(function (item) {
          return item.gig
        })
      })
    },
    isUserOnline (date) {
      return helper.isUserOnline(date)
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  max-height: 375px;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}

::v-deep .card {
  .body {
    -ms-flex-direction: row !important;
    -webkit-flex-direction: horizontal !important;
    -webkit-flex-orient: normal !important;
    flex-direction: row !important;

    .assets {
      width: calc(50%) !important;
      display: flex !important;
    }

    .content {
      width: calc(50%) !important;
    }
  }

  .content {
    z-index: 1 !important;

    .avatar {
      display: none !important;
    }
  }

  .actions {
    top: 6px !important;
    left: 8px !important;
    z-index: 1 !important;
    right: auto !important;
  }

  .user-online.badge {
    position: relative !important;
  }

  .username {
    color: $light-grey !important;
  }

  .copy, .task-description {
    min-height: 0px !important;
  }

  .order-1 {
    order: 1 !important;
  }

  .order-2 {
    order: 2 !important;
  }

  .order-3 {
    order: 3 !important;
  }
}
</style>
